<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_consolidated_checks_pending"
            no-data-text="No pending checks found"
            class="rq-tab-content-grid"
            :actions="selectionActions"
            :config="gridConfig"
            search-mode="field"
            :data-source="filteredItems"
            @selectionChanged="onSelectionChanged"
            @create-check="onCreateCheck"
            @create-check-with-memo="onCreateWithMemoCheck"
            integrated-search
            rq-filters
            force-floating-header>
            <template #toolbar>
                <ul class="nav ms-auto me-3">
                    <li :class="{ 'nav-item': true }">
                         <rq-report-button
                            text="View Report"
                            :disabled="printOptionDisabled"
                            :name="reportOptions.title"
                            :path="reportOptions.path"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
             </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import MemoDialog from "@config/shared/NoteDialog.vue";

    export default {
        name:"ConsolidatedChecksPendingGrid",
        props: {
            items: {type: Array, required: true},
            reportOptions: { type: Object, default: null},
            printOptionDisabled: {type: Boolean, default: true}
        },
        data () {
            return {
                popover: {
                    visible: false,
                    target: null,
                    item: {},
                },
                selectedCheck: {},
                totalAmount: 0,
                errorMessage: '',
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "CanAddChecks",
                    "IsAppReadOnly"
                ]);
            },
            filteredItems() {
                if (_.isEmpty(this.selectedCheck)) {
                    return this.items;
                } else {
                    return _.filter(this.items, ["transferredToOrder", this.selectedCheck.transferredToOrder]);
                }
            },
            selectionActions() {
                const self = this;
                return [
                    {
                        name: "create-check",
                        text: "Create Check",
                        eventName: "create-check",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (!self.localSecurity.CanAddChecks || self.localSecurity.IsAppReadOnly) {
                                return "Access Restricted";
                            }
                            return false;
                        }
                    },
                    {
                        name: "create-check-with-memo",
                        text: "Create Check with Memo",
                        eventName: "create-check-with-memo",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (!self.localSecurity.CanAddChecks || self.localSecurity.IsAppReadOnly) {
                                return "Access Restricted";
                            }
                            return false;
                        }
                    },
                ];
            },
        },

        watch: {
            errorMessage(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.$emit("update-error-message", this.errorMessage);
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.hideColumnChooser();
            },

            createCheck(items, memo){
                const self = this;
                if(!self.localSecurity.CanAddChecks) return;
                if (!_.isNullOrEmpty(memo)) items[0].memo = memo;
                let apiPromise = self.$api.ConsolidatedChecksApi.createCheck(items);
                return self.$rqBusy.wait(apiPromise);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    keyExpr: self.itemKey,
                    noDataText: "No pending checks found",
                    columns: [
                        {
                            dataField: "bankName",
                            dataType: "string",
                            caption: "Escrow Account",
                            width: 200,
                            allowFiltering: false
                        },
                        {
                            dataField: "payeeName",
                            dataType: "string",
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updatePayeePopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updatePayeePopover();
                                    }
                                }
                            })
                        },
                        DxGridUtils.dateColumn({
                            dataField: "checkDate",
                            caption: "Date",
                        }),
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: { type: "currency", precision: 2 }
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "Original File Number",
                            width: 200
                        },
                        {
                            dataField: "transferredToOrder",
                            dataType: "string",
                            caption: "File Number",
                            width: 200
                        }
                    ],
                    summary: {
                        totalItems: [
                            { name: "TotalChecksLabel", column: 0, alignment: "left", displayFormat: "SELECTED CHECKS TOTAL" },
                            { name: "TotalChecksAmount", column: "amount", alignment: "right", customizeText: () => accounting.formatMoney(self.totalAmount) }
                        ]
                    },
                    allowColumnReordering: false
                };
            },

            initNonReactiveVariables() {
                this.itemKey = "checksID";
            },

            refresh(clear=true) {
                if (clear) this.clear();
                this.gridInstance.refresh();
            },

            updatePayeePopover(item=null, target=null) {
                const self = this;

                self.popover = {
                    visible: false,
                    target: null,
                    item: {}
                };
                if(_.isNil(target) && _.isNil(item)) {
                    self.$events.$emit("show-popover", self.popover);
                    return;
                }

                self.$nextTick(()=>{
                    self.popover.item = _.pick(item, [
                        "payeeCompanyID",
                        "payeeName",
                        "payeeAddress1",
                        "payeeAddress2",
                        "payeeCity",
                        "payeeState",
                        "payeeZip"
                    ]);
                    self.popover.target = target;
                    self.popover.visible = true;
                    self.$events.$emit("show-popover", self.popover);
                });
            },

            onCreateCheck(e) {
                const self = this;
                if(!e || !e.data) return;
                self.createCheck(e.data)
                    .then(result => {
                        self.$toast.success({ message: `Consolidated Check Created Successfully.` });
                    }).catch(error => {
                        self.errorMessage = error.errorMessage;
                    })
                    .finally(() => {
                        self.$events.$emit("reload-data", 1);
                    });
            },

            onCreateWithMemoCheck(e) {
                if(!e || !e.data) return;
                this.showMemoDialog(e.data);
            },

            onSelectionChanged(e) {
                const self = this;
                self.totalAmount = _.sumBy(e.selectedRowsData, "amount");
                if (e.selectedRowsData.length >= 1) {
                    self.selectedCheck = e.selectedRowsData[0];
                }
                if (e.selectedRowsData.length == 0) {
                    self.selectedCheck = {};
                }
                self.refresh(false);
            },

            showMemoDialog(items) {
                const self = this;
                let onOk = (e) => {
                    let memo = e.component.localNotes || null;
                    self.createCheck(items, memo)
                        .then(result => {
                            self.$toast.success({ message: `Consolidated Check Created Successfully.` });
                        }).catch(error => {
                            self.errorMessage = error.errorMessage;
                        })
                        .finally(() => {
                            self.$events.$emit("reload-data", 1);
                        });
                    return true;
                };
                let title = 'Consolidated Check Memo';
                self.$dialog.open({
                    title: title,
                    height: "auto",
                    width: "600",
                    scrollable: false,
                    component: MemoDialog,
                    props: {
                        notes: null,
                        maxlength: 200,
                        rows: 3
                    },
                    onOk: onOk
                });
            },
        }
    }
</script>