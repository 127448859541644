<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage?.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Search Disbursements" headerSize="lg" class="browser-filter-section" v-model:expanded="filtersExpanded" @keyup.enter="onSearch" collapsible>
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="!filtersExpanded" class="nav browser-filter-display">
                        <li class="nav-item" v-if="bankFilterDisplay != 'All'">
                            <div class="filter-name">Bank:</div>
                            <div class="filter-value">{{bankFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="escrowUnitFilterDisplay != 'All'">
                            <div class="filter-name">Escrow Unit:</div>
                            <div class="filter-value">{{escrowUnitFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="statusFilterDisplay != 'All'">
                            <div class="filter-name">Status:</div>
                            <div class="filter-value">{{statusFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="activeRequest.ordersID > 0">
                            <div class="filter-name">File:</div>
                            <div class="filter-value">{{activeRequest.gfNo}}</div>
                        </li>
                        <li class="nav-item" v-if="checkFilterDisplay.length > 0">
                            <div class="filter-name">Check#:</div>
                            <div class="filter-value">{{checkFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="amountFilterDisplay.length > 0">
                            <div class="filter-name">Amount:</div>
                            <div class="filter-value">{{amountFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="dateFilterDisplay.length > 0">
                            <div class="filter-name">Date:</div>
                            <div class="filter-value">{{dateFilterDisplay}}</div>
                        </li>
                        <li class="nav-item" v-if="hasActiveFilter">
                            <b-btn
                                variant="link"
                                class="btn-theme"
                                @click="onClearSearch">Clear All Filters
                            </b-btn>
                        </li>
                    </ul>
                </transition>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_clear"
                                variant="theme"
                                @click="onClearSearch">Clear</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_search_checks"
                                variant="theme"
                                :disabled="errorMessage.length > 0 && !hasFilter || fileNumberInvalid"
                                @click="onSearch">Search</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_escrow_bank">Escrow Account</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_bank', id: 'dtp_escrow_bank' }"
                        :items="escrowBanks"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.bankCompanyID"
                        placeholder="All Escrow Accounts..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_escrow_unit">Escrow Unit</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'dtp_escrow_unit', id: 'dtp_escrow_unit' }"
                        :items="escrowUnits"
                        value-expr="id"
                        display-expr="name"
                        v-model="request.escrowUnitID"
                        placeholder="All Escrow Units..."
                        :search-enabled="true"
                        :show-clear-button="true"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <label for="dtp_status">Status</label>
                    <dx-tag-box
                        automation_id="dtp_status"
                        class="form-control"
                        :data-source="escrowStatuses2"
                        display-expr="name"
                        value-expr="id"
                        :search-enabled="true"
                        :show-selection-controls="true"
                        :show-clear-button="true"
                        :max-displayed-tags="3"
                        :show-drop-down-button="true"
                        placeholder="All Statuses..."
                        apply-value-mode="useButtons"
                        v-model:value="request.escrowCheckStatusIDs"
                    />
                </div>
                <div class="col col-3 col-xl-3 form-group">
                    <file-number-input
                        automation_id="txt_gfno"
                        label="File #"
                        v-model:order-id="request.ordersID"
                        v-model:has-error="fileNumberInvalid"
                        v-model="request.gfNo"
                        show-clear-button
                        show-search-button
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="txt_check_date_range">Date Range</label>
                    <rq-date-range
                        id="txt_check_date_range"
                        v-model:start-date="request.checkDateFrom"
                        v-model:end-date="request.checkDateTo"
                        match-value="before-clear"
                        match-field="end"
                        format="MM/dd/yyyy"
                        type="date"
                        show-calendar-drop-downs
                        show-clear-buttons
                        no-calendars
                        borderless
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="txt_check_number">Check Number Range</label>
                    <rq-number-range
                        automation_id="txt_check_number"
                        format-type="basic"
                        :commas="false"
                        :decimals="0"
                        v-model:start-value="request.checkNumberFrom"
                        v-model:end-value="request.checkNumberTo"
                        match-value="before-clear"
                        match-field="end"
                        show-clear-buttons
                        allow-nulls
                        no-prefix
                    />
                </div>
                <div class="col col-6 col-xl-4 form-group">
                    <label for="txt_check_amount">Amount Range</label>
                    <rq-number-range
                        automation_id="txt_check_amount"
                        prepend-icon="fas fa-dollar-sign"
                        :decimals="2"
                        v-model:start-value="request.checkAmountFrom"
                        v-model:end-value="request.checkAmountTo"
                        match-value="before-clear"
                        match-field="end"
                        show-clear-buttons
                        input-groups
                        allow-nulls
                        no-prefix
                        commas
                    />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_escrow_checks"
            :actions="selectionActions"
            :config="gridConfig"
            title="Disbursements"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            @edit="onEditItem"
            @change-payee-file-contact="onChangePayeeToFileContact"
            @change-payee-company="onChangePayeeToCompany"
            @change-payee-manual="onChangePayeeToManual"
            @change-reconciliation-date="onChangeReconciliation"
            @change-date="onChangeDate"
            @change-status-void="onChangeStatus($event, 3)"
            @change-status-stop-pay="onChangeStatus($event, 4)"
            @delete="onDeleteItem"
            @navigate="onGotoFile"
            @rowDoubleClick="onEditItem"
            export-file-name="escrow_checks_data"
            :strikethrough-if-true="['inactive']"
            hide-show-column-chooser
            integrated-search
            :hide-search="items.length == 0"
            rq-filters>
            <template #toolbar>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.html.hover.top :title="`${(readOnly || !localSecurity.AllowAccessToQuickCheckQuickDepositEntry) ?'Access Restricted' : 'Add New Disbursement'}`">
                        <b-button
                            automation_id="btn_add"
                            variant="theme"
                            @click="onAddCheck"
                            :disabled="readOnly || !localSecurity.AllowAccessToQuickCheckQuickDepositEntry"
                            >Add
                        </b-button>
                    </li>
                    <li class="nav-item" v-if="hasActiveFilter">
                        <rq-report-button
                            text="View Report"
                            :disabled="readOnly || !hasActiveFilter || fileNumberInvalid || items.length == 0 || hasFilterChanged"
                            :path="reportOptions.path"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
        <address-detail-popover
            title="Payee Information"
            :target="payeePopover.target"
            container="check-browser"
            :display-number="payeePopover.detail.payeeCompanyID"
            :name="payeePopover.detail.payee"
            :address1="payeePopover.detail.payeeAddress1"
            :address2="payeePopover.detail.payeeAddress2"
            :city="payeePopover.detail.payeeCity"
            :state="payeePopover.detail.payeeState"
            :zip="payeePopover.detail.payeeZip"
            v-model:visible="payeePopover.visible"
        />
        <check-writing-approval-popover
            container="#check-browser"
            :popover="approvalPopover"
            v-model:visible="approvalPopover.visible"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EscrowCheckDto, EscrowCheckSearchRequest }  from "../models";
    import { CheckStatus, CheckStatusFilter } from '../../file/check-writing/enums';
    import { MoneyMovementApprovalDto, CheckRequestDto }  from "../../file/check-writing/models";
    import { EscrowAccountingCheckStatus } from '../enums';
    import { SystemLookupItem } from "@/shared/models/models";
    import AddressDetailPopover from "@/shared/components/rq/AddressDetailPopover";
    import CheckWritingApprovalPopover from "@/shared/components/rq/CheckWritingApprovalPopover";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import FileContactSelection from "@file-shared/components/FileContactSelection";
    import CheckWritingManualPayee from "../../file/check-writing/components/CheckWritingManualPayee";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import QuickCheckForm from "../components/QuickCheckForm";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import { DateTime } from "luxon";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { isCheckDateBeforeLastRecon, getIsDateDisabledFunction } from "../helpers";

    const DEFAULT_ERROR_MESSAGE = "Please correct the highlighted errors on screen to continue.";
    const INVALID_FILE_ERROR_MESSAGE = "Invalid File Number";
    const FINALIZED_RECON_WARNING = "<div class='alert alert-danger mt-1'><svg class='rq-icon-symbol-lg alert-icon pe-1'><use href='#rq-fas-exclamation-triangle'></use></svg>One or more Item is dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.</div>";

    export default {
        name: "CheckBrowserList",
        mixins: [ GridSystemLookupMixin ],
        components: { AddressDetailPopover, CheckWritingApprovalPopover, FileNumberInput },
        data () {
            return {
                items: [],
                request: new EscrowCheckSearchRequest(),
                activeRequest: new EscrowCheckSearchRequest(),
                errorMessage: "",
                selectionActions: [],
                approvalPopover: { visible: false, target: null, item: {} },
                payeePopover: { target: null, detail: {}, visible: false },
                filtersExpanded: true,
                fileNumberInvalid: false
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowDeleteCheck",
                    "AllowDeleteCheckFromEscrowAccounting",
                    "AllowEscrowAdminBrowserChange",
                    "AllowFinalizedReconModifications",
                    "AllowAccessToQuickCheckQuickDepositEntry",
                    "CheckWriting_ScreenAccess",
                    "CheckPreDateDays",
                    "CheckPostDateDays",
                    "IsAppReadOnly"
                ]);
            },
            hasFilter(){
                return !_.isEqual(new EscrowCheckSearchRequest(this.request), new EscrowCheckSearchRequest());
            },
            hasActiveFilter(){
                return !_.isEqual(new EscrowCheckSearchRequest(this.activeRequest), new EscrowCheckSearchRequest());
            },
            hasFilterChanged(){
                return !_.isEqual(new EscrowCheckSearchRequest(this.activeRequest), new EscrowCheckSearchRequest(this.request));
            },
            dateFilterDisplay(){
                if (_.isNil(_.get(this, "activeRequest.checkDateFrom")) && _.isNil(_.get(this, "activeRequest.checkDateTo"))) {
                    return "";
                }
                return `${this.getDate(this.activeRequest.checkDateFrom, "Anything")} to ${this.getDate(this.activeRequest.checkDateTo, "Anything")}`;
            },
            amountFilterDisplay(){
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) > 0) {
                    return `$${this.activeRequest.checkAmountFrom} to $${this.activeRequest.checkAmountTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) == 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) > 0) {
                    return `Anything to $${this.activeRequest.checkAmountTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkAmountFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkAmountTo", 0) == 0) {
                    return `$${this.activeRequest.checkAmountFrom} to Anything`;
                }
                return "";
            },
            checkFilterDisplay(){
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) > 0) {
                    return `${this.activeRequest.checkNumberFrom} to ${this.activeRequest.checkNumberTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) == 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) > 0) {
                    return `Anything to ${this.activeRequest.checkNumberTo}`;
                }
                if (_.getNumber(this, "activeRequest.checkNumberFrom", 0) > 0 && _.getNumber(this, "activeRequest.checkNumberTo", 0) == 0) {
                    return `${this.activeRequest.checkNumberFrom} to Anything`;
                }
                return "";
            },
            bankFilterDisplay(){
                return _.isNil(this.activeRequest.bankCompanyID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_ACCOUNTS, this.activeRequest.bankCompanyID);
            },
            escrowUnitFilterDisplay(){
                return _.isNil(this.activeRequest.escrowUnitID) ? "All" : this.lookupHelpers.getLookupItemName(this.lookupItems.ESCROW_UNITS, this.activeRequest.escrowUnitID);
            },
            statusFilterDisplay(){
                let itemNames = _.map(this.activeRequest.escrowCheckStatusIDs, id => _.find(this.escrowStatuses2, { id }).name);
                if (_.size(itemNames) == 0) return "";
                if (_.size(itemNames) > 2) return `${itemNames.length} Selected`;
                return _.join(_.sortBy(itemNames, ['name']), ", ");
            },
            readOnly() { return !this.localSecurity.AllowEscrowAdminBrowserChange || this.localSecurity.IsAppReadOnly; },
            userBankCompanyID(){
                let branchID = _.getNumber(this.lookupHelpers.getBranch(this.user.branchID), "bankCompanyID", 0);
                return branchID == 0 ? null : branchID;
            },
            reportItems() {
                return [
                    {
                        text: "Disbursement Browser Report",
                        path: "System Reports\\File Specific\\Disbursement Browser",
                        disabled: this.readOnly || !this.hasActiveFilter || this.fileNumberInvalid || this.items.length == 0 || this.hasFilterChanged,
                        immediate: true,
                        automation_id: "prt_disbursement_browser",
                        options: new ReportOptionsDto({
                            title: 'Disbursement Browser',
                            immediate: true,
                            parameters: {
                                p_Banks:        this.request.bankCompanyID || '',
                                p_OrdersID:     this.request.ordersID || this.request.ordersID !== 0 ? this.request.ordersID : null ,
                                p_StatusIDs:    this.request.escrowCheckStatusIDs,
                                p_StartDate:    this.request.checkDateFrom ,
                                p_EndDate:      this.request.checkDateTo ,
                                p_StartNumber:  this.request.checkNumberFrom ,
                                p_EndNumber:    this.request.checkNumberTo,
                                p_MinAmount:    this.request.checkAmountFrom ,
                                p_MaxAmount:    this.request.checkAmountTo
                            },
                        })
                    },
                    {
                        text: "Disbursements by Date Range",
                        path: "System Reports\\Accounting\\Disbursements For Date Range",
                        disabled: false,
                        immediate: false,
                        automation_id: "prt_disbursements_for_date_range",
                        options: new ReportOptionsDto({
                            title: 'Disbursements by Date Range',
                            immediate: false,
                            parameters: {
                                p_Banks:         this.request.bankCompanyID || '',
                                p_CheckStatuses: this.request.escrowCheckStatusIDs,
                                p_Regions:       [],
                                p_Branches:      [],
                                p_StartDate:     this.request.checkDateFrom ,
                                p_EndDate:       this.request.checkDateTo ,
                            },
                        })
                    },
                    {
                        text: "Open Invoices",
                        path: "System Reports\\Accounting\\Invoices Report",
                        disabled: false,
                        immediate: false,
                        automation_id: "prt_invoices_report",
                        options: new ReportOptionsDto({
                            title: 'Open Invoices',
                            immediate: true,
                            parameters: {
                                p_Regions:       [],
                                p_Branches:      [],
                                p_TitleCompanies:[],
                                p_StartDate:     this.request.checkDateFrom,
                                p_EndDate:       this.request.checkDateTo,
                                p_InvoiceStatus: 0,
                                p_CompanyID:     this.request.bankCompanyID
                            },
                        })
                    },
                    {
                        text: "Receipt ID",
                        path: "System Reports\\Accounting\\Receipts ID",
                        disabled: false,
                        immediate: false,
                        automation_id: "prt_receipts_id",
                        options: new ReportOptionsDto({
                            title: 'Receipt ID',
                            immediate: true,
                            parameters: {
                                p_StartDate:     this.request.checkDateFrom,
                                p_EndDate:       this.request.checkDateTo,
                                p_Regions:       [],
                                p_Branches:      [],
                                p_Banks:         this.request.bankCompanyID || '',
                                p_DepositStatusExt: ''
                            },
                        })
                    },
                    {
                        text: "Receipts by Date Range",
                        path: "System Reports\\Accounting\\Receipts For Date Range",
                        disabled: false,
                        immediate: false,
                        automation_id: "prt_receipts_for_date_range",
                        options: new ReportOptionsDto({
                            title: 'Receipts by Date Range',
                            immediate: true,
                            parameters: {
                                p_StartDate:     this.request.checkDateFrom,
                                p_EndDate:       this.request.checkDateTo,
                                p_Regions:       [],
                                p_Branches:      [],
                                p_Banks:         this.request.bankCompanyID || '',
                                p_DepositStatus: '',
                                p_TypeOfFunds:   '',
                            },
                        })
                    }
                ];
            },
            reportOptions() {
                let reportOptions = new ReportOptionsDto({
                    text: "Print",
                    reportPath: "System Reports\\File Specific\\Disbursement Browser",
                    title: 'Disbursement Browser',
                    parameters: {
                        p_Banks:        this.request.bankCompanyID || '',
                        p_OrdersID:     this.request.ordersID || this.request.ordersID !== 0 ? this.request.ordersID : null ,
                        p_StatusIDs:     this.request.escrowCheckStatusIDs,
                        p_StartDate:    this.request.checkDateFrom,
                        p_EndDate:      this.request.checkDateTo,
                        p_StartNumber:  this.request.checkNumberFrom ,
                        p_EndNumber:    this.request.checkNumberTo,
                        p_MinAmount:    this.request.checkAmountFrom ,
                        p_MaxAmount:    this.request.checkAmountTo
                    }
                });

                return reportOptions;
            },
        },

        watch: {
            fileNumberInvalid(newValue, oldValue) {
                if(newValue || newValue === oldValue || (this.errorMessage !== INVALID_FILE_ERROR_MESSAGE && this.errorMessage !== DEFAULT_ERROR_MESSAGE)) return;
                this.errorMessage = "";
            },
            "request.gfNo":{
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.fileNumberInvalid = false;
                    this.request.ordersID = 0;
                }
            },
        },

        methods: {
            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            setOrderID(){
                const self = this;
                if (_.isNil(this.request.gfNo) || this.request.gfNo === ""){
                    self.request.ordersID = 0;
                    self.activeRequest.ordersID = 0;
                } else {
                    self.request.ordersID = self.items.length === 0 ? 0 : self.items[0].ordersID;
                    self.activeRequest.ordersID = self.request.ordersID;
                }
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getChecks(self.request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new EscrowCheckDto(i));
                        self.activeRequest = new EscrowCheckSearchRequest(self.request);
                        self.setOrderID();
                    })
                    .catch(error => {
                        if (error.errorMessage == INVALID_FILE_ERROR_MESSAGE) {
                            self.fileNumberInvalid = true;
                        } else {
                            self.fileNumberInvalid = false;
                            console.error(error);
                            self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        }
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchLookupData(checksID) {
                const self = this;
                let apiPromise = self.$api.EscrowAccountingApi.getLookups(checksID, "check");
                return self.$rqBusy.wait(apiPromise);
            },

            getDate(dateStr, nullValue = null) {
                return _.isNullOrEmpty(dateStr) ? nullValue : dateStr;
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Check";
                self.itemTypeNamePlural = "Checks";
                self.itemKey = "checksID";
                self.statuses = CheckStatus.lookupItems;
                self.escrowStatuses = EscrowAccountingCheckStatus.lookupItems;
                self.escrowStatuses2 = _.orderBy(CheckStatusFilter.lookupItems, ['name'], ['asc']);
                self.escrowBanks = self.lookupHelpers.getLookupItems(self.lookupItems.USER_ESCROW_ACCOUNTS);
                self.escrowUnits = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_UNITS);
                self.request.bankCompanyID = self.activeRequest.bankCompanyID = self.userBankCompanyID;
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        disabled: function(e) {
                            return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "change-status",
                        eventName: "change-status",
                        text: "Manage",
                        children: (self.readOnly) ? [] : [
                            { name: "change-date", text: "Check Date", eventName: "change-date", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return  self.readOnly || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isReconciled', true]) || _.size(_.groupBy(e.data, "bankCompanyID")) > 1 || _.some(e.data, ['checkStatus', CheckStatus.None]) } },
                            { name: "change-reconciliation-date", text: "Reconciliation Date", eventName: "change-reconciliation-date", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.readOnly || _.some(e.data, ['checkStatus', CheckStatus.Void]) || _.some(e.data, ['checkStatus', CheckStatus.StopPay]) || _.some(e.data, ['checkStatus', CheckStatus.None])|| _.some(e.data, ['isTransfer', true]) } },
                            { name: "change-status-void", text: "Void/Unvoid", eventName: "change-status-void", requireSelection: true, allowMultiSelection: true,
                                    disabled: function(e) { return self.readOnly || _.some(e.data, ['isTransfer', true]) || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['checkStatus', CheckStatus.Cleared]) || _.some(e.data, ['isConsolidated', true]) || _.some(e.data, ['isReconciled', true]) || _.some(e.data, ['checkStatus', CheckStatus.StopPay]) || _.some(e.data, ['checkStatus', CheckStatus.None])  } },
                            { name: "change-status-stop-pay", text: "Stop Pay", eventName: "change-status-stop-pay", requireSelection: true, allowMultiSelection: true, disabled: function(e) { return self.readOnly || _.some(e.data, ['isTransfer', true]) || _.some(e.data, ['isEFER', true]) || _.some(e.data, ['isConsolidated', true]) || _.some(e.data, ['checkStatus', CheckStatus.Void]) || _.some(e.data, ['checkStatus', CheckStatus.None]) || _.some(e.data, ['isReconciled', true]) } },
                        ],
                        disabled: function(e) {
                            //if a user is not allowed to alter finalized recons then if the check date falls before the last recon date then it's locked
                            if (!self.localSecurity.AllowFinalizedReconModifications && _.some(e.data, isCheckDateBeforeLastRecon)) return "Access Restricted. One or more of the items selected is on a finalized recon.";
                            return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "change-payee",
                        text: "Change Payee",
                        children: (self.readOnly) ? [] : [
                            { name: "change-payee-file-contact", text: "File Contact", eventName: "change-payee-file-contact", requireSelection: true, disabled: function(e) { return self.readOnly || _.parseBool(e.data.isReconciled, false)} },
                            { name: "change-payee-company", text: "Company", eventName: "change-payee-company", requireSelection: true, disabled: function(e) { return self.readOnly || _.parseBool(e.data.isReconciled, false) } },
                            { name: "change-payee-manual", text: "Manual", eventName: "change-payee-manual", requireSelection: true, disabled: function(e) { return self.readOnly || _.parseBool(e.data.isReconciled, false) } },
                        ],
                        disabled: function(e) {
                            return (self.readOnly) ? 'Access Restricted' : false;
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            return (self.readOnly || !self.localSecurity.AllowDeleteCheckFromEscrowAccounting || (!self.localSecurity.AllowFinalizedReconModifications && _.some(e.data, ['isReconciled', true]))) ? 'Access Restricted' : false;
                        }
                    },
                    { name: "goto", text: "Go To File", eventName: "navigate", requireSelection: true, tooltip: `Go to ${ self.localSecurity.CheckWriting_ScreenAccess ? `Check Writing` : `File Main`} for this File.` },
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    height: "100%",
                    remoteOperations: false,
                    columns: [
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File#",
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "checkStatus",
                            dataType: "number",
                            caption: "Status",
                            cellTemplate: DxGridUtils.checkStatusCellTemplate({
                                idLookup: "checkStatus",
                                idAppend: "check-approval-popup-info-",
                                statuses: self.statuses,
                                handlers:{
                                    click(cellElement, cellInfo, e) {
                                        self.updateApprovalPopover(cellInfo.data.moneyMovementApprovalID, e.target);
                                        e.stopPropagation();
                                    },
                                }
                            }),
                            calculateSortValue: rowData => {
                                let status = _.find(self.statuses, r => r.id === rowData.checkStatus) || {};
                                return status.name || "";
                            },
                            lookup: {
                                dataSource: self.statuses,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            rqFilter: {
                                disabled: true,
                                disabledTooltip: "Use fields at the top of the page to filter this column."
                            }
                        },
                        {
                            dataField: "numberDisplay",
                            dataType: "string",
                            caption: "Number",
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "checkDate",
                            dataType: "date",
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 100,
                            minWidth: 100
                        },
                        {
                            dataField: "payee",
                            dataType: "string",
                            caption: "Payee",
                            width: 300,
                            cellTemplate: DxGridUtils.infoIconCellTemplate({
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updatePayeePopover(cellInfo.data, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updatePayeePopover();
                                    }
                                }
                            })
                        },
                        {
                            dataField: "reconciliationDate",
                            dataType: "date",
                            caption: "Recon Date",
                            cellTemplate: DxGridUtils.reconDateCellTemplate
                        },
                        {
                            dataField: "stopPayDate",
                            dataType: "date",
                        },
                        {
                            dataField: "voidDate",
                            dataType: "date",
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS
                        }),
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "escrowUnitID",
                                dataType: "number",
                                caption: "Escrow Unit",
                                rqFilter: {
                                    disabled: true,
                                    disabledTooltip: "Use fields at the top of the page to filter this column."
                                }
                            },
                            lookupKey: self.lookupItems.ESCROW_UNITS
                        }),
                        {
                            dataField: "usersDisplayName",
                            dataType: "string",
                            caption: "Person",
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onAddCheck(e) {
                if (this.readOnly || !this.localSecurity.AllowAccessToQuickCheckQuickDepositEntry) return;
                let item = new EscrowCheckDto({bankCompanyID: 0});
                this.showQuickCheckDialog(item);
            },

            onChangeDate(e) {
                if(!e || !e.data) return;
                this.showChangeStatusDialog(e.data, CheckStatus.None);
            },

            onChangeStatus(e, checkStatusID) {
                if(!e || !e.data) return;
                this.showChangeStatusDialog(e.data, checkStatusID);
            },

            onChangePayeeToFileContact(e) {
                if(!e || !e.data) return;
                this.showFileContactsDialog(e.data);
            },

            onChangePayeeToCompany(e) {
                if(!e || !e.data) return;
                this.showCompanyDialog(e.data);
            },

            onChangePayeeToManual(e) {
                if(!e || !e.data) return;
                this.showManualPayeeDialog(e.data);
            },

            onChangeReconciliation(e) {
                if(!e || !e.data) return;
                let items = e.data;
                let bankCompanyID = _.get(items[0], "bankCompanyID");
                if (_.every(items, ["bankCompanyID", bankCompanyID])) {
                    this.showReconciliationChangeDialog(items);
                } else {
                    this.$dialog.messageBox("WARNING", "You cannot change the reconciliation date of multiple checks with different banks.");
                }
            },

            onGotoFile(e) {
                if(!e || !e.data) return;
                let orderId = _.parseNumber(e.data.ordersID, 0);
                let tab = 2;
                if(this.localSecurity.CheckWriting_ScreenAccess) {
                    this.$router.push({ name: "check-writing", params: { orderId, tab } });
                } else {
                    this.$router.push({ name: "oe:main", params: { orderId } });
                }
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (self.readOnly || !self.localSecurity.AllowDeleteCheckFromEscrowAccounting) return;
                let items = e.data;
                let dateWarning = "";
                if (_.some(items, isCheckDateBeforeLastRecon)) {
                    dateWarning = FINALIZED_RECON_WARNING;
                }
                let ok = function (args) {
                    self.errorMessage = "";
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.EscrowAccountingApi.deleteChecks(ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(data => {
                            let message = ids.length > 1 ? `${ids.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?${dateWarning}`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                if(!e || !e.data || this.readOnly) return;
                this.errorMessage = "";
                this.showQuickCheckDialog(e.data);
            },

            onSearch() {
                this.errorMessage = "";
                if(!this.hasFilter) {
                    this.errorMessage = "Please enter at least one value to search Disbursements";
                    return;
                }
                if(this.fileNumberInvalid) {
                    this.errorMessage = DEFAULT_ERROR_MESSAGE;
                    return;
                }
                this.fetchData();
            },

            onClearSearch() {
                this.filtersExpanded = true;
                this.request = new EscrowCheckSearchRequest();
                this.activeRequest = new EscrowCheckSearchRequest();
                this.items = [];
                this.refresh();
            },

            updateApprovalPopover(moneyMovementApprovalID=null, target=null) {
                const self = this;
                self.$api.MoneyMovementApprovalsApi.getApproval(moneyMovementApprovalID)
                    .then(data => {
                        let newID = _.get(target, "id") || null;
                        let lastID = _.get(self.approvalPopover, "target") || null;
                        let isNewItem = !_.isEqual(newID, lastID);
                        if (isNewItem) {
                            if (!_.isNil(lastID)) {
                                self.approvalPopover.visible = false;
                                self.approvalPopover = _.assign({}, self.approvalPopover);
                            }
                            self.approvalPopover.target = newID;
                            self.approvalPopover.item = new MoneyMovementApprovalDto(data);
                            self.approvalPopover.visible = true;
                        } else {
                            self.approvalPopover.visible = !self.approvalPopover.visible;
                        }
                        self.$nextTick(() => {
                            self.approvalPopover = _.assign({}, self.approvalPopover);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error getting approval info.` });
                    });
            },

            updatePayeePopover(item=null, target=null) {
                const self = this;
                self.payeePopover = { visible: false, target: null, detail: {}};
                if(_.isNil(target) || _.isNil(item)) return;
                let detail = _.pick(item, ["payeeCompanyID", "payee", "payeeAddress1", "payeeAddress2", "payeeCity", "payeeState", "payeeZip"]);
                self.$nextTick(()=>{
                    self.payeePopover = { target, detail, visible: true };
                });
            },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            saveCheckPayee(item, changes){
                const self = this;
                if (self.readOnly) return;
                let apiPromise = self.$api.CheckWritingApi.saveCheckPayee(item, changes);
                return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error saving payee info.` });
                            return false;
                        });
            },

            toastSaveSuccess(numSaved) {
                const self = this;
                let message = numSaved > 1
                    ? `${numSaved} ${self.itemTypeNamePlural} were saved.`
                    : `${self.itemTypeName} was saved.`;
                self.$toast.success(message);
            },

            async saveReconciliation(reconciliationID, ids){
                const self = this;
                if (self.readOnly) return;
                let apiPromise = self.$api.EscrowAccountingApi.saveCheckReconciliation(reconciliationID, ids);
                try {
                    await self.$rqBusy.wait(apiPromise);
                    self.toastSaveSuccess(ids.length);
                    self.fetchData();
                    return true;
                }
                catch(error) {
                    self.$toast.error("Error saving reconciliation.");
                    self.errorMessage = error.message;
                    return false;
                }
            },

            async saveStatusChange(date, checkStatusID, ids){
                const self = this;
                if (self.readOnly) return;
                const request = {
                    changeDate: date,
                    changeStatusID: checkStatusID,
                    checkIDs: ids
                };
                let apiPromise = self.$api.EscrowAccountingApi.saveCheckStatusChange(request);
                try {
                    await self.$rqBusy.wait(apiPromise);
                    self.toastSaveSuccess(ids.length);
                    self.fetchData();
                    return true;
                }
                catch(error) {
                    self.$toast.error(`Error saving ${checkStatusID === CheckStatus.None ? "date" : "status"} change.`);
                    self.errorMessage = error.message;
                    return false;
                }
            },

            showCompanyDialog (item) {
                const self = this;
                let original = new CheckRequestDto({...item});
                let okHandler = function (e) {
                    self.errorMessage = "";
                    let result = _.get(e, "originalEvent.data", e.data);
                    if (!result)
                        return false;
                    let request = new CheckRequestDto({...result, ordersID: item.ordersID, checksID: item.checksID} );
                    request.buyerSellerID = null;
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    return self.saveCheckPayee(request, changes);
                };

                let dialogArgs = {
                    defaultSelectedCompanyId: original.payeeCompanyID,
                };

                self.$dialog.open({
                    title: "Select Company or Contact",
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    props: dialogArgs,
                    closeOnEsc: true,
                    onOk (e) {
                        return okHandler(e);
                    },
                    onCancel (e) {
                        self.refresh('disbursementsList');
                        return true;
                    }
                });
            },

            showFileContactsDialog(item) {
                const self = this;
                let original = new CheckRequestDto(item);
                let onOk = (e) => {
                    let request = new CheckRequestDto({...e.component.selectedContact, ordersID: item.ordersID, checksID: item.checksID} );
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    let hasForwarding = _.parseBool(e.component.selectedContact.hasForwardingAddress);
                    if (hasForwarding) {
                        let onYes = (e) => {
                            request.useForwardingAddress = true;
                            return self.saveCheckPayee(request, changes);
                        };
                        let onNo = (e) => {
                            request.useForwardingAddress = false;
                            return self.saveCheckPayee(request, changes);
                        };
                        self.$dialog.confirm("Address Selection", "Use forwarding address?", onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
                    } else {
                        return self.saveCheckPayee(request, changes);
                    }
                };
                let onCancel = e => {
                    self.refresh('disbursementsList');
                };
                self.$dialog.open({
                    title: `Select File Contact`,
                    height: "700",
                    width: "1000",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: FileContactSelection,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        ordersID: item.ordersID
                    },
                });
            },

            showManualPayeeDialog(item) {
                const self = this;
                let original = new CheckRequestDto(item);
                let onOk = (e) => {
                    let request = new CheckRequestDto(e.component.item);
                    let changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    if(_.isNullOrEmpty(request.payee))
                        return false;
                    if (changes.length == 0 ) {
                        self.$toast.info({ message: `No Changes Detected.` });
                        return true;
                    }
                    request.buyerSellerID = null;
                    request.payeeCompanyID = null;
                    changes = self.getAuditChanges(original, request, ["useForwardingAddress"]);
                    return self.saveCheckPayee(request, changes);
                };
                let onCancel = e => {
                    self.refresh('disbursementsList');
                };
                self.$dialog.open({
                    title: `Manual Payee`,
                    height: "400",
                    width: "900",
                    resizable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: CheckWritingManualPayee,
                    onOk: onOk,
                    onCancel: onCancel,
                    props: {
                        item: _.clone(original)
                    },
                });
            },

            getCheckDateDialogAlerts(items, checkStatusID) {
                let result = [];
                let infoMessage = checkStatusID === CheckStatus.Void
                    ? "Leave Blank to Unvoid"
                    : checkStatusID === CheckStatus.StopPay
                        ? "Leave Blank to undo Stop Pay"
                        : "";

                if(!_.isEmpty(infoMessage)) {
                    result.push({
                        variant: "info",
                        icon: "fas fa-exclamation-circle",
                        message: infoMessage,
                        position: "after"
                    });
                }

                if (_.some(items, isCheckDateBeforeLastRecon)) {
                    result.push({
                        variant: "danger",
                        icon: "fas fa-exclamation-triangle",
                        message: "One or more items are dated within a finalized reconciliation time period and will recalculate affected finalized reconciliations.",
                        position: "after"
                    });
                }

                return result;
            },

            showChangeStatusDialog(items, checkStatusID) {
                const self = this;
                let ids = _.map(items, self.itemKey);
                let typeName = items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
                let count = items.length > 1 ? `${items.length} ` : "";

                let dateType = checkStatusID === CheckStatus.Void ? "Void"
                    : checkStatusID === CheckStatus.StopPay  ? "Stop Pay"
                    : "";
                let titleLabel = _.isEmpty(dateType) ? "Change Date" : dateType;

                let title = `${titleLabel} - ${count}${typeName}`;
                let label = _.isEmpty(dateType) ? "Date" : `${dateType} Date`;
                let inputId = _.isEmpty(dateType) ? "dtp_check_date" : "dtp_status_date";
                let isRequired = checkStatusID === CheckStatus.None;

                let editorOptions = {};
                if (checkStatusID === CheckStatus.None) {
                    let lastFinalizedReconDate = _.get(items, "[0].lastFinalizedReconDate");
                    editorOptions.disabled = _.isNil(lastFinalizedReconDate);
                    if(!editorOptions.disabled) {
                        editorOptions.disabledDates = getIsDateDisabledFunction(
                            lastFinalizedReconDate,
                            self.localSecurity.AllowFinalizedReconModifications,
                            self.localSecurity.CheckPreDateDays,
                            self.localSecurity.CheckPostDateDays
                        );
                    }
                }

                let alerts = self.getCheckDateDialogAlerts(items, checkStatusID);

                self.$dialog.promptDateInput({
                    title,
                    label,
                    inputId,
                    width: 535,
                    isRequired,
                    editorOptions,
                    alerts,
                    onOk: async e => {
                        let date = DateTimeHelper.toFormat(e.value) || null;
                        try {
                            await self.saveStatusChange(date, checkStatusID, ids);
                            return true;
                        }
                        catch(error) {
                            self.errorMessage = error.message;
                            console.error(error);
                            return true;
                        }
                    }
                });
            },

            async showReconciliationChangeDialog(items) {
                const self = this;
                let ids = _.map(items, self.itemKey);
                let now = DateTime.now().startOf("day");
                let targetCheck = _.maxBy(items, item => now.diff(DateTime.fromISO(item.checkDate), "days").days) || items[0];
                let checksID = _.get(targetCheck, self.itemKey);
                try {
                    let result = await self.fetchLookupData(checksID);
                    let reconciliations = _.map(result.reconciliations, i => new SystemLookupItem(i));
                    self.$dialog.promptSelect({
                        title: "Change Reconciliation Date",
                        label: "Reconciliation",
                        inputId: "cmb_reconciliation_id",
                        isRequired: false,
                        items: reconciliations,
                        valueExpr: "id",
                        displayExpr: "name",
                        alerts: [{
                            variant: "info",
                            icon: "fas fa-exclamation-circle",
                            message: "Leave Blank to Unreconcile/Unclear",
                            position: "after"
                        }],
                        onOk: async e => {
                            let reconciliationID = _.getNumber(e, "selectedValue", 0);
                            await self.saveReconciliation(reconciliationID, ids);
                            return true;
                        }
                    });
                }
                catch(error) {
                    self.errorMessage = error.message;
                }
            },

            showQuickCheckDialog(item) {
                const self = this;
                let onOk = (e) => {
                    return e.component.save()
                            .then(result => {
                                if (!result) self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                self.fetchData();
                                return true;
                            })
                            .catch(error => {
                                e.component.errorMessage = error.errorMessage;
                                console.log(error);
                                return false;
                            });
                };
                let onCancel = (e) => {
                    let form = e.component;

                    if (form.hasChanges && !form.isNew) {
                        try {
                            form.validateOnCancel();
                        } catch(error) {
                            form.errorMessage = error.message;
                            return false;
                        }
                        let onDiscard = function (args) {
                            if(self.hasFilter) {
                                self.refresh();
                            }
                            return true;
                        };
                        let onSave = (e) => {
                            return form.save()
                                    .then(result => {
                                        self.$toast.success({ message: `${self.itemTypeName} was saved.` });
                                        self.fetchData();
                                        return true;
                                    })
                                    .catch(error => {
                                        form.errorMessage = error.errorMessage;
                                        console.log(error);
                                        return false;
                                    });
                        };
                        if(!form.hasFormError){
                            self.$dialog.confirm("Confirm Cancel",
                            `You have pending changes, do you want to save or discard changes?`,
                            onSave,
                            onDiscard,
                            { cancelTitle: 'Discard', okTitle: 'Save'});
                        }
                    }
                };
                let title = item.checksID == 0 ? `Add Quick Disbursement` : `Edit Disbursement: ${_.isNullOrEmpty(item.numberDisplay) ? '(blank)' : item.numberDisplay}`;
                self.$dialog.open({
                    title: title,
                    width: "800",
                    adaptive: true,
                    closeOnEsc: true,
                    component: QuickCheckForm,
                    props: {check: item},
                    onOk: onOk,
                    onCancel: onCancel
                });
            },
        }
    }
</script>
