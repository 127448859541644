import { ref, unref, computed } from "vue";
import { CheckStatus } from "@check-writing/enums";
import { DisplayAnticipatedDeposits } from "@config/enums";
import { DateTime } from "luxon";

export const useConsolidatedChecksGridActions = ({ localSecurity={}, lastFinalizedReconDate=null, processing=false }) => {

    let processingVal = unref(processing);
    let localSecurityVal = unref(localSecurity);
    let lastFinalizedReconDateVal = unref(lastFinalizedReconDate);

    const isFromSameOrder = items => _.every(items, (obj,idx,arr) => idx === 0 || obj.consolidatedOrdersID === arr[idx-1].consolidatedOrdersID);
    const checkDateLocked = c => !_.isNil(c.checkDate) && !localSecurityVal.AllowFinalizedReconModifications && DateTime.fromISO(c.checkDate).diff(DateTime.fromISO(lastFinalizedReconDateVal), "days").days <= 0;
    const checkStatusLocked = c => _.includes([CheckStatus.Hold, CheckStatus.StopPay, CheckStatus.Void, CheckStatus.Rejected, CheckStatus.Cleared, CheckStatus.Wire], c.checkStatus);

    const hasActionPermission = name => {
        switch(name) {
            case "print-check": return (localSecurityVal.AllowPrintChecks && localSecurityVal.AnticipatedDeposits === DisplayAnticipatedDeposits.Full) && !localSecurityVal.IsAppReadOnly;
            case "change-payee": return localSecurityVal.AllowChangePayee && !localSecurityVal.IsAppReadOnly;
            case "void": return localSecurityVal.AllowCheckVoid && !localSecurityVal.IsAppReadOnly;
            case "stop-pay": return localSecurityVal.AllowCheckStop && !localSecurityVal.IsAppReadOnly;
            case "hold": return !localSecurityVal.IsAppReadOnly;
            case "assign": return !localSecurityVal.IsAppReadOnly;
            case "edit-memo": return !localSecurityVal.IsAppReadOnly;
            case "transfer": return !localSecurityVal.IsAppReadOnly;
            case "file-transfer": return !localSecurityVal.IsAppReadOnly;
            case "wire-transfer": return !localSecurityVal.IsAppReadOnly;
            case "undo": return localSecurityVal.AllowUndoCheck && !localSecurityVal.IsAppReadOnly;
            case "delete": return (localSecurityVal.AllowDeleteCheck && localSecurityVal.AllowDeleteCheckFromEscrowAccounting) && !localSecurityVal.IsAppReadOnly;
        }
        return true;
    };

    const isActionDisabled = ({ name }, items) => {
        if(!name || _.isEmpty(items)) return true;

        if(_.includes(["void","stop-pay","hold","undo","delete"], name)
            && _.some(items, checkDateLocked)) {
            return () => "Access Restricted. One or more of the items selected is on a finalized recon.";
        }

        if(!hasActionPermission(name)) return () => "Access Restricted";

        let item = items?.[0] || {};
        switch(name) {
            case "print-check": {
                if(_.some(items, c => c.checkStatus === CheckStatus.Hold)) return () => "Some checks are on Hold";
                return !isFromSameOrder(items) || _.some(items, c => !!c.hasCheckNumber || _.lte(c.amount, 0) || _.parseNumber(c.payeeCompanyID, 0) === 0) || localSecurityVal.IsAppReadOnly;
            }
            case "change-payee": return item?.hasStatus || item?.isWire || localSecurityVal.IsAppReadOnly;
            case "file-transfer": return checkStatusLocked(item) || item?.isIssued || localSecurityVal.IsAppReadOnly;
            case "wire-transfer": return item?.checkStatus === CheckStatus.Hold || item?.isWire || !!item?.hasCheckNumber || _.lte(item?.amount, 0) || _.parseNumber(item?.payeeCompanyID, 0) === 0 || item?.isIssued || localSecurityVal.IsAppReadOnly;
            case "void": return _.some(items, c => c.isReconciled || !c.isIssued || checkStatusLocked(c)) || localSecurityVal.IsAppReadOnly;
            case "stop-pay": return _.some(items, c => c.isReconciled || !c.isIssued || checkStatusLocked(c)) || localSecurityVal.IsAppReadOnly;
            case "hold": return _.some(items, c => c.isWire || !_.includes([CheckStatus.None, CheckStatus.Hold], c.checkStatus)) || localSecurityVal.IsAppReadOnly;
            case "undo": return _.some(items, c => c.isReconciled || !_.includes([CheckStatus.Issued, CheckStatus.Cleared, CheckStatus.Wire, CheckStatus.ExpectedWireOut], c.checkStatus)) || localSecurityVal.IsAppReadOnly;
            case "delete": return _.some(items, "hasStatus") || localSecurityVal.IsAppReadOnly;
        }
        return false;
    };

    const isActionVisible = ({ name }, items) => {
        if(!name || _.isEmpty(items)) return false;
        switch(name) {
            case "assign": return processingVal;
            case "wire-transfer": return !items?.[0]?.isWire;
            case "wire-details": return items?.[0]?.isWire;
            case "void": return !processingVal;
            case "stop-pay": return !processingVal;
            case "undo": return !processingVal;
        }
        return true;
    };

    const selectedRows = ref([]);
    const selectionActions = computed(() => {
        let items = selectedRows.value || [];
        let actions = [
            { name: "go-to-file", text: "Go To File", requireSelection: true, tooltip: "Go to Consolidated File" },
            { name: "assign", text: "Assign", requireSelection: true, tooltip: "Assign" },
            { name: "print-check", text: "Print", allowMultiSelection: true, tooltip: "Print Check(s)" },
            { name: "edit-memo", text: "Edit Memo", requireSelection: true, tooltip: "Edit Check Memo" },
            { name: "change-payee", text: "Change Payee", requireSelection: true, tooltip: "Change Check Payee" },
            { name: "view-payee", text: "View Payee", requireSelection: true, tooltip: "View Check Payee Information" },
            { name: "transfer", text: "Transfer", children: [
                { name: "file-transfer", text: "To File", requireSelection: true },
                { name: "wire-transfer", text: "To Wire Transfer", requireSelection: true },
                { name: "wire-details", text: "Wire Transfer Details", requireSelection: true }
            ]},
            { name: "void", text: "Void", allowMultiSelection: true, tooltip: "Void check(s)" },
            { name: "stop-pay", text: "Stop Pay", allowMultiSelection: true, tooltip: "Place Stop Pay on the selected check(s)" },
            { name: "hold", text: "Hold", allowMultiSelection: true, tooltip: "Place or Reverse a Hold on the selected check(s)" },
            { name: "undo", text: "Undo", allowMultiSelection: true, tooltip: "Undo check(s)" },
            { name: "delete", text: "Delete", allowMultiSelection: true, tooltip: "Delete check(s)" }
        ];

        const updateActionState = aList => {
            if(_.isEmpty(aList)) return;
            _.forEach(aList, a => {
                a.disabled = isActionDisabled(a, items);
                a.visible = isActionVisible(a, items);
                updateActionState(a.children);
            });
        }
        updateActionState(actions);
        return actions;
    });

    function onSelectionChanged(e) {
        selectedRows.value = e.selectedRowsData?.slice() || [];
    }

    return {
        selectionActions,
        onSelectionChanged
    };
}